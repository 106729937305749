import request from '../request'

export function fetchList(params) {
    return request({
        url: '/common-service/softVersion/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/common-service/softVersion/add',
        method: 'POST',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/common-service/softVersion/del',
        method: 'POST',
        data
    })
}