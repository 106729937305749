<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="链接">
              <FileUpload @uploaded="uploaded">
                <a-button type="primary">
                  {{ fileList.length > 0 ? "已上传" : "上传" }}
                </a-button>
              </FileUpload>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/components/file-upload";
import { add } from "@/api/setting/app-version";

export default {
  name: "addAppVersion",

  components: {
    FileUpload,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      fileList: [],
    };
  },

  methods: {
    uploaded(list) {
      this.fileList = list;
    },

    handleSubmit(e) {
      e.preventDefault();

      if (this.fileList.length === 0) {
        this.$message.error("请上传 App 安装包！");
        return;
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          add({
            ...values,
            url: this.fileList[0].completePath,
          }).then(() => {
            this.$close(this.$route.path);
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
